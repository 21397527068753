.privatemidrow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.selectGarden span{
    font-size: 16px;
    margin-right: 10px;
}
.createGarden{
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2px;
}
.createGarden div{
    background-color: #86C983;
    border-radius: 3px;
    padding: 6px 12px;
    margin: 0px 2px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: -2px 3px 5px #b1b1b1;
    color: white;
    text-align: center;
}
.createGarden div:hover{
    opacity: 0.9;
}
.selectDrop{
    padding: 4px 10px;
    font-size: 15px;
    cursor: pointer;
    background-color: #86C983;
    border-color: #86C983;
    border-radius: 3px;
    color: white;
}
.privateGardenContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.somethingWrong{
    color: red;
    font-size: 28px;
    font-weight: 500;
    margin-top: 40px;
}
.noResults{
    color: gray;
    font-size: 28px;
    font-weight: 500;
    margin-top: 40px;
}
.gardenSpecification{
    margin-left: 10px;
}
@media only screen and (max-width: 680px){
    .selectGarden{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}