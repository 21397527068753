.modal-main {
    position:fixed;
    background: white;
    width: 40%;
    min-width: 300px;
    height: 220px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
}
.senddataContainer{
      width: 100%;
      height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.senddataContainer h3{
      color:rgb(8, 8, 44);
      margin-top: -20px;
}
  
.display-block {
    display: block;
}
  
.display-none {
    display: none;
}
.closemodalV{
    width:100px;
    height: 30px;
    position: absolute;
    top: -10px;
    right: -50px;
    cursor: pointer;
}
.gobackButton{
    padding: 10px 20px;
    background-color: rgb(0, 44, 0);
    color: white;
    cursor: pointer;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}
.popButtons{
    width: 50%;
    min-width: 290px;
    margin: 20px auto 0 ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.popButtons div:first-child{
    width: 70px;
    padding: 5px;
    background-color: white;
    color: red;
    border: 1px solid red;
    text-align: center;
    border-radius: 3px ;
    font-size: 24px;
    cursor: pointer;
}
.popButtons div:last-child{
    width: 70px;
    padding: 5px;
    background-color: red;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 3px ;
    font-size: 24px;
    cursor: pointer;
}
.resMessage{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px auto;
    text-align: center;
}
.succ{
    font-size: 18px;
    color: rgb(25, 197, 25);
}
.err{
    font-size: 18px;
    color: rgb(238, 26, 26);
}
.leave{
    padding: 10px;
    width: 90px;
    background-color: rgb(8, 8, 44);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}

.columnPanel{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}