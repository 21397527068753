.modalGeneral{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.modal-mainPanel {
    position:fixed;
    background: white;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
.display-block {
    display: block;
  }
  
.display-none {
   display: none;
}
.closemodalV{
    width:100px;
    height: 30px;
    position: absolute;
    top: -10px;
    right: -50px;
    cursor: pointer;
}