.header{
    width: 100%;
    height: 100px;
    background-color: #25410a;
    position: relative;
}
.mainmain{
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: row;
    height: auto;
}
.mainmainmain{
    width: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
}
.mainmainmainClose{
    width: 100%;
    position: relative;
    min-height: calc(100vh - 120px);
}


.breadcrumbs{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

/*Mobile*/
@media only screen and (max-width: 500px) {
    .mainmainmain{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}