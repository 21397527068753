/*MENU*/
.DashboardHead{
    width: 100%;
    height: 120px;
    background-color: #F2F2F2;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.hambButtonDash{
    position: absolute;
    top:0px;
    left: 0px;
    width: 180px;
    height: 120px;
    cursor: pointer;
    background-color: #001D42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hambButtonDashClosed{
    position: absolute;
    top:0px;
    left: 0px;
    width: 180px;
    height: 120px;
    cursor: pointer;
    background-color: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hamb{
    width: 60px;
}
.DashLogo{
    position: absolute;
    top:60px;
    left: 300px;
    width: 100px;
    transform: translate(-50%,-50%);
}
.UserDash{
    position: absolute;
    top: 60px;
    right: 15px;
    transform: translate(-50%,-50%);
    width: 30px;
}
.LangClass{
    position: absolute;
    top: 60px;
    right: 150px;
    transform: translate(-50%,-50%);
    width: 30px;
}
.Notifications{
    position: absolute;
    top: 60px;
    right: 42px;
    transform: translate(-50%,-50%);
    width: 60px;
}
.NotificationsNum{
    position: absolute;
    top: 52px;
    right: 74px;
    transform: translate(-50%,-50%);
    text-align: center;
    background-color: red;
    color: white;
    padding: 2px 6px;
    border-radius: 50%;
    font-size: 12px;
}
.dashboardgardensMap{
    width: 100%;
    max-width: 1400px;
    height: auto;
    padding: 20px 40px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.dashboardgardensTips{
    width: 100%;
    max-width: 1600px;
    height: auto;
    padding: 20px 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.tipoftheday{
    width: 330px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
/*MOving Card*/
.cardTemplate{
    width: 320px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #6DAC21;
    border-radius: 10px;
    padding: 10px;
}
.stepContainer{
    display: flex;
    flex: 1 0 200px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.steps{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
}
.steps h3{
    margin-left: 15px;
}
.allsteps{
    width: 100%;
    max-width: 1000px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid rgb(180, 180, 180);
}
.stepTemplate{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stepTitle{
    color: #000000;
    font-size: 32px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.imagesContainer{
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
}
.colT{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}
.mainStepImage{
    width: 160px;
}
.backStepImage{
    width: 70px;
}
.steptext{
    color: #353535;
    font-size: 16px;
    text-align: center;
    margin-top: 25px;
    max-width: 600px;
}


.imgRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 20px;
}
.imgRow span{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    color: white;
    text-align: center;
}
.mainImg{
    width: 120px;
}
.arrows{
    width: 35px;
    cursor: pointer;
}
.headtext{
    text-align: center;
    width: 100%;
    color: white;
    overflow-y: auto;
}
/*Map*/
.dashboardmap{
    height: 400px;
    width: 100%;
    z-index: 0;
    border-radius: 10px;
    border: 1px solid #8BB836;
}
.popUp{
    height: 100px;
    width: 300px;
}
.popUpContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popUpTitle{
    font-size: 18px;
    font-weight: 600;
}
.notDisplay{
    opacity: 0;
    visibility: hidden;
}
@media only screen and (max-width: 500px) {
    .DashLogo{
        display: none;
    }
    .hambButtonDash{
        position: absolute;
        top:0px;
        left: 0px;
        width: 180px;
        height: 120px;
        cursor: pointer;
        background-color: #F2F2F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (max-width: 635px) {
    .dashboardgardensTips{
        width: 100%;
        height: auto;
        padding: 20px 40px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
    }
    .stepContainer{
        display: flex;
        flex: 1 0 200px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
    .steps{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: auto;
    }
    .allsteps{
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
    }
    .tipoftheday{
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}