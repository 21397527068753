.flexcolumncenterfull{
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items: center;
    width: 100%;
}
.flexcolumntopfull{
    display: flex;
    flex-direction: column;
    justify-content:flex-start ;
    align-items: center;
    width: 100%;
}
.serverresponseError{
    color: red;
    font-style: italic;
}
.links{
    text-decoration: none;
}
.circularProgressContainer{
    margin: 80px auto 0;
}
.PageTitle{
    font-size: 28px;
}
.PageHead{
    padding: 20px 40px;
    width: 100%;
    min-width: 300px;
}
.allHR{
    width: 100%;
}
.circularP{
    margin: 40px auto;
    width: 40px;
}
@media only screen and (max-width: 500px) {
    .PageHead{
        padding: 10px ;
        width: 100%;
    }
}
.inputsG{
    width: 100%;
    max-width: 300px;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}
.hidden{
    display: none;
    opacity: 0;
    visibility: hidden;
}