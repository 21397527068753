.CreategardenContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.addgardenfield{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 40px;
    margin-bottom: 40px;
}
.addgardenfieldtitle{
    font-size: 24px;
}
.addgardenfieldlabel{
    font-size:14px;
    color: rgb(104, 104, 104);
}
.addgardenfieldRest{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}
.inputsG{
    width: 100%;
    max-width: 300px;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}
.inputsN{
    width: 100%;
    max-width: 120px;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    margin-left: 10px;
}
.addimg{
    width: 30px;
    cursor: pointer;
    margin-left: 20px;
}
.deleteimg{
    width: 30px;
    cursor: pointer;
}
.addgardenfieldArray{
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.vegArrayData{
    margin-top: 5px;
    width: 100%;
    max-width: 490px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    background-color: ghostwhite;
    padding: 8px;
}
.vegArrayDataH{
    margin-top: 10px;
    width: 100%;
    max-width: 490px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(236, 236, 236);
    padding: 8px;
}
.centerCont{
    width: 33%;
    text-align: center
}

/*EDIT*/
.nameInput{
    width: 120px;
    padding:  4px;
    text-align: center;
}
.quantInput{
    width: 60px;
    padding:  4px;
    text-align: center;
}


/*options*/
.vegOptionCont{
    width: 155px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    margin-right: 5px;
}
.vegOptionCont img{
    height: 50px;
}
.vegOptionCont span{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}
.vegOptionSelected{
    background-color: #2F80ED;
    border-radius: 5px;
    width: 134px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    margin-top: 5px;
    cursor: pointer;
}
.vegOptionSelected img{
    width: 28px;
    margin-left: 8px;
}
.vegOptionSelected span{
    color: white;
    font-size: 16px;
    font-weight: 400;
}
.vegOption{
    border-radius: 5px;
    width: 134px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    margin-top: 5px;
    background-color: #F7F9FA;
    border: 1px solid #2F80ED;
    box-sizing: border-box;
    color: #2F80ED;
    cursor: pointer;
}
/*Map*/
.dragableMarker{
    width: 100%;
    max-width: 600px;
    height: 300px;
    margin-top: 10px;
}
.latlonGarden{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.latlonGarden div{
    margin-top: 15px;
}
/*buttons*/
.errorCont{
    width: 50%;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
}
.errbuttonsCont{
    width: 80%;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
    min-width: 350px;
}
.editbuttonsCont{
    width: 80%;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
    min-width: 350px;
}
.editbuttonsCont span{
    background-color: #27AE60;
    border-radius: 5px;
    font-size: 22px;
    padding: 12px 16px;
    color: white;
    margin: 10px;
    cursor: pointer;
}
.buttonsCont{
    width: 80%;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
    min-width: 350px;
}
.hiden{
    display: none;
    opacity: 0;
    visibility: hidden;
}
.buttonsCont :first-child{
    background-color: #27AE60;
    border-radius: 5px;
    font-size: 22px;
    padding: 12px 16px;
    color: white;
    margin: 10px;
    text-align: center;
    cursor: pointer;
}
.buttonsCont :last-child{
    background-color: #fc270b;
    border: 1px solid #fc270b;
    text-align: center;
    border-radius: 5px;
    font-size: 22px;
    padding: 12px 16px;
    color: white;
    margin: 10px;
    cursor: pointer;
}
.buttonsCont div:first-child:hover{
    background-color: #2b8d54;
    cursor: pointer;
}
.buttonsCont div:last-child:hover{
    background-color: white;
    border: 1px solid #fc270b;
    cursor: pointer;
    color: #fc270b;
}
@media only screen and (max-width: 680px){
    .buttonsCont :first-child{
        background-color: #27AE60;
        border-radius: 5px;
        font-size: 16px;
        padding: 8px 10px;
        color: white;
        margin: 10px;
        text-align: center;
        cursor: pointer;
    }
    .buttonsCont :last-child{
        background-color: #fc270b;
        border: 1px solid #fc270b;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        padding: 8px 10px;
        color: white;
        margin: 10px;
        cursor: pointer;
    }
}
.errorCreate{
    color: red;
    font-size: 22px
}
.sensorFields{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
}
.sensorFieldsVar{
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
}
.titleCodes{
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
}
.inputsSensor{
    width: 100%;
    max-width: 80px;
    padding: 3px 5px;
    display: inline-block;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    margin: 2px;
}
.variableS{
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid gainsboro;
    padding: 7px 10px;
    border-radius: 5px;
    margin-right: 3px;
}
