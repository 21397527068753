@import url(https://fonts.googleapis.com/css?family=Rubik);
body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', cursive;
}
* {
  box-sizing: border-box;
}


.header{
    width: 100%;
    height: 100px;
    background-color: #25410a;
    position: relative;
}
.mainmain{
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: row;
    height: auto;
}
.mainmainmain{
    width: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
}
.mainmainmainClose{
    width: 100%;
    position: relative;
    min-height: calc(100vh - 120px);
}


.breadcrumbs{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

/*Mobile*/
@media only screen and (max-width: 500px) {
    .mainmainmain{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}
.loginPage{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainUserPanel{
    width: 100%;
    max-width: 500px;
    height: 600px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border-radius: 3px;
}
.LoginLogo{
    width: 100px;
}
.LoginLogoText{
    width: 200px;
    margin-left: 10px ;
}
.credentials{
    height: 650px;
    width: 100%;
    max-width: 400px;
    margin: 0px auto ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
}
.credFields{
    margin-top: 20px;
}
.responseField{
    margin-top: 60px;
}
.LoginButton{
    padding: 12px 35px;
    border-radius: 3px;
    background-color: green;
    color: white;
    margin-top: 10px;
    cursor: pointer;
}
.LoginButton:hover{
    background-color: rgb(0, 167, 0);
}
.Registerhere{
    margin-top: 40px;
}
.contacthelp{
    margin-top: 20px;
    text-align: center;
}
.Registerhere span , .contacthelp span{
    color: rgb(62, 62, 221);
    text-decoration: underline;
    cursor: pointer;
}
.modalGeneral{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.modal-mainPanel {
    position:fixed;
    background: white;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }
.display-block {
    display: block;
  }
  
.display-none {
   display: none;
}
.closemodalV{
    width:100px;
    height: 30px;
    position: absolute;
    top: -10px;
    right: -50px;
    cursor: pointer;
}
.registerPanel{
    width: 80%;
    min-width: 330px;
    max-width: 560px;
    height: 540px;
}
.registerFields{
    height: 650px;
    width: 100%;
    max-width: 400px;
    margin: -15px auto 0;
}
.regField{
    margin-top: 0px;
    padding: 0px;
}
.userlevelDrop{
    margin: 30px auto -20px;
    padding: 5px;
    width: 160px;
    font-size: 15px;
}
.flexcolumncenterfull{
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items: center;
    width: 100%;
}
.flexcolumntopfull{
    display: flex;
    flex-direction: column;
    justify-content:flex-start ;
    align-items: center;
    width: 100%;
}
.serverresponseError{
    color: red;
    font-style: italic;
}
.links{
    text-decoration: none;
}
.circularProgressContainer{
    margin: 80px auto 0;
}
.PageTitle{
    font-size: 28px;
}
.PageHead{
    padding: 20px 40px;
    width: 100%;
    min-width: 300px;
}
.allHR{
    width: 100%;
}
.circularP{
    margin: 40px auto;
    width: 40px;
}
@media only screen and (max-width: 500px) {
    .PageHead{
        padding: 10px ;
        width: 100%;
    }
}
.inputsG{
    width: 100%;
    max-width: 300px;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}
.hidden{
    display: none;
    opacity: 0;
    visibility: hidden;
}
.headerLand{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}
/*wave effect*/
.waveContainer{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(#469476, #004d49);
}


.iccsLogo{
    cursor: pointer;
    width: 150px;
}
.headerLeft{
    margin-left: 40px;
    margin-top: 80px;
}
.headerRight{
    margin-right: 100px;
    margin-top: 80px;
    width: 500px;
}
.LandingMenu{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
}
.LandingMenu span{
    cursor: pointer;
    padding: 3px 0;
    color: white;
}
.LandingMenu .register{
    padding: 12px 10px;
    background-color: #FFD7AB;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
.LandingMenuMobile{
    display: none;
}
.mobileMenuLand{
    display: none;
}
.mobileMenuLand span{
    width: 100%;
    text-align: center;
    color: white;
    padding: 8px 0;
    cursor: pointer;
}
.mobileMenuLand span:hover{
    background-color: white;
    opacity: 0.6;
    color: black;
}
.LandingMain{
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.bottomland{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-bottom: 100px;
}
.bottomland span{
    padding: 18px 48px;
    border-radius: 10px;
    background-color: white;
    color: black;
    cursor: pointer;
}
@media only screen and (max-width: 500px){
    .LandingMenu{
        display: none;
    }
    .LandingMenuMobile{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
    }
    .mobileMenuLand{
        width: 100%;
        height: 200px;
        z-index: 10;
        background-color: rgb(44, 83, 0);
        position:absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        top: 110px
    }
}

/*Main page*/
.MainPageLand{
    width: 100%;
    height: 100%;
    
}
.topwave{
    width: 100%;
    height: 80%;
    position: relative;
}
.bottomwave{
    width: 100%;
    height: 20%;
    background-color: #F2ECE4;
}
.MainPageLand:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 40%;
    width: 70%;
    height: 30%;
    background-color: #F2ECE4;
    right: 0px;
    top: 75vh;
}
.MainPageLand:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 30% 90%;
    width: 50%;
    height: 40vh;
    background-color: #F2ECE4;
    left: -40px;
    top: 65vh;
}

/*main content*/
.landImg{
    position: absolute;
    width: 40%;
    z-index: 5;
    top: 50vh;
    left: 30vw;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.landWelcome{
    position: absolute;
    width: 30%;
    z-index: 1;
    top: 40vh;
    left: 75vw;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
}
.landTitle{
    color: white;
    font-size: 34px;
    font-weight: 600;
    margin: 10px 0;
}
.landmain{
    color: white;
    font-size: 20px;
}

/*Landing Transition to mobile*/
@media only screen and (max-width: 680px){
    .MainPageLand{
        width: 100%;
        height: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 20px;
    }
    .topwave{
        position: relative;
        width: 100%;
        height: 100% ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .bottomwave{
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    .MainPageLand:before {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    .MainPageLand:after {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    /*main content*/
    .landImg{
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    .landWelcome{
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        top: 30%;
        left: 50%;
        width: 80%;
        z-index: 0;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .landTitle{
        color: white;
        font-size: 34px;
        font-weight: 600;
        margin: 10px 0;
    }
    .landmain{
        color: white;
        font-size: 20px;
    }
    .iccsLogo{
        cursor: pointer;
        width: 80px;
    }
}
/*MENU*/
.DashboardHead{
    width: 100%;
    height: 120px;
    background-color: #F2F2F2;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.hambButtonDash{
    position: absolute;
    top:0px;
    left: 0px;
    width: 180px;
    height: 120px;
    cursor: pointer;
    background-color: #001D42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hambButtonDashClosed{
    position: absolute;
    top:0px;
    left: 0px;
    width: 180px;
    height: 120px;
    cursor: pointer;
    background-color: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hamb{
    width: 60px;
}
.DashLogo{
    position: absolute;
    top:60px;
    left: 300px;
    width: 100px;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.UserDash{
    position: absolute;
    top: 60px;
    right: 15px;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 30px;
}
.LangClass{
    position: absolute;
    top: 60px;
    right: 150px;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 30px;
}
.Notifications{
    position: absolute;
    top: 60px;
    right: 42px;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 60px;
}
.NotificationsNum{
    position: absolute;
    top: 52px;
    right: 74px;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    text-align: center;
    background-color: red;
    color: white;
    padding: 2px 6px;
    border-radius: 50%;
    font-size: 12px;
}
.dashboardgardensMap{
    width: 100%;
    max-width: 1400px;
    height: auto;
    padding: 20px 40px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.dashboardgardensTips{
    width: 100%;
    max-width: 1600px;
    height: auto;
    padding: 20px 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.tipoftheday{
    width: 330px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
/*MOving Card*/
.cardTemplate{
    width: 320px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #6DAC21;
    border-radius: 10px;
    padding: 10px;
}
.stepContainer{
    display: flex;
    flex: 1 0 200px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.steps{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
}
.steps h3{
    margin-left: 15px;
}
.allsteps{
    width: 100%;
    max-width: 1000px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid rgb(180, 180, 180);
}
.stepTemplate{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stepTitle{
    color: #000000;
    font-size: 32px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.imagesContainer{
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
}
.colT{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}
.mainStepImage{
    width: 160px;
}
.backStepImage{
    width: 70px;
}
.steptext{
    color: #353535;
    font-size: 16px;
    text-align: center;
    margin-top: 25px;
    max-width: 600px;
}


.imgRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 20px;
}
.imgRow span{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    color: white;
    text-align: center;
}
.mainImg{
    width: 120px;
}
.arrows{
    width: 35px;
    cursor: pointer;
}
.headtext{
    text-align: center;
    width: 100%;
    color: white;
    overflow-y: auto;
}
/*Map*/
.dashboardmap{
    height: 400px;
    width: 100%;
    z-index: 0;
    border-radius: 10px;
    border: 1px solid #8BB836;
}
.popUp{
    height: 100px;
    width: 300px;
}
.popUpContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popUpTitle{
    font-size: 18px;
    font-weight: 600;
}
.notDisplay{
    opacity: 0;
    visibility: hidden;
}
@media only screen and (max-width: 500px) {
    .DashLogo{
        display: none;
    }
    .hambButtonDash{
        position: absolute;
        top:0px;
        left: 0px;
        width: 180px;
        height: 120px;
        cursor: pointer;
        background-color: #F2F2F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (max-width: 635px) {
    .dashboardgardensTips{
        width: 100%;
        height: auto;
        padding: 20px 40px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
    }
    .stepContainer{
        display: flex;
        flex: 1 0 200px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
    .steps{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: auto;
    }
    .allsteps{
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
    }
    .tipoftheday{
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
.sideBar{
    width: 180px;
    background-color: #001D42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}
.sideBarContainer .option{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 15px auto;
    text-align: center;
    color: white;
    padding: 20px;
    cursor: pointer;
}
.sideBarContainer .option:hover{
    background-color: #002d68;
    color: white;
}
.mobileMenu{
    display: none;
}
.sideBarClose{
    display: none;
}
.sideBarContainer{
    width: 100%;
}

.notificationSection{
    cursor: pointer;
}
.logOut{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    color: white;
    padding: 20px;
    cursor: pointer;
}
.logOut:hover{
    background-color: #002d68;
    color: white;
}
.sideImage{
    width: 20px;
    margin-right: 5px;
    margin-left: -5px;
}
/*Mobile*/
@media only screen and (max-width: 500px) {
    .sideBar{
        width: 100%;
        height: 400px;
        background-color: #001D42;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        top: 120px;
        justify-content: space-between;
    }
    .sideBarContainer .option{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 20px auto;
        background-color: inherit;
        text-align: center;
        color: white;
        padding: 5px;
        border-radius: 0px;
        cursor: pointer;
    }
    .sideBarContainer .option:hover{
        background-color: white;
        color: #001D42;
    }
    .sideImage{
        display: none;
    }
    .logOut{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        padding: 15px;
        cursor: pointer;
    }
}
.fieldBoxContainer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
}
.fieldBox{
    width: 160px;
    height: 160px;
    min-height: 160px;
    max-width: 160px;
    margin: 30px;
    border: 1px solid rgb(173, 173, 173);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 5px 10px 8px #888888;
    cursor: pointer;
    color: rgb(3, 24, 3);
    background-color: rgb(244, 252, 244);
}

/*mOBILE*/
@media only screen and (max-width: 500px) {
    .fieldBoxContainer{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
    }
}
.ForecastSubTitle{
    font-size: 22px;
}
.forecastInfo{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}
.stationDrop{
    padding: 7px;
    margin-top: 10px ;
    border-radius: 5px;
    width: 280px;
    font-size: 16px;
    background-color: #C4C4C4;
    cursor: pointer;
}
.stationIMG{
    margin: 10px 0 0 20px ;
    cursor: pointer;
}

.forecastTable{
    margin: 20px 40px;
    padding: 5px;
    background-color: #8CE1EB;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.forecastStation{
    background-color: #001D42;
    color: white;
    width: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.windForContainer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}
.forecastwindy{
    width: 55%;
    min-width: 320px;
    height: 510px;
    margin-left: 20px;
}
.agValues{
    width: 40%;
    min-width: 300px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: 20px;
    margin-bottom: 5px;
    background-color: #e2efff;
}
.columnWrap{
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
/**/
.foreValue{
    background-color: #FFFFFF;
    color: white;
    width: 250px;
    height: 90px;
    border-radius: 4px;
    border: 1px solid rgb(182, 182, 182);
    box-shadow: 2px 2px 3px rgb(194, 194, 194);
    color: black;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:5px 20px;
    margin: 5px;
}
.underTitle{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
}
.underTitle span{
    text-align: right;
}
.circle{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #97c2fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ImgTitle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.forecastImg{
    width: 28px;
}
.buttonsSettings{
    width: 100%;
    padding:40px;
}

.editextcredSection{
    width: 100%;
    padding:10px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.actionbuttons{
    padding: 10px 18px;
    color: white;
    background-color: rgb(0, 184, 0);
    border-radius: 5px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 15px;
}
.actionbuttons:hover{
    background-color: rgb(0, 102, 0);
}
.buttonRow{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.privatemidrow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.selectGarden span{
    font-size: 16px;
    margin-right: 10px;
}
.createGarden{
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2px;
}
.createGarden div{
    background-color: #86C983;
    border-radius: 3px;
    padding: 6px 12px;
    margin: 0px 2px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: -2px 3px 5px #b1b1b1;
    color: white;
    text-align: center;
}
.createGarden div:hover{
    opacity: 0.9;
}
.selectDrop{
    padding: 4px 10px;
    font-size: 15px;
    cursor: pointer;
    background-color: #86C983;
    border-color: #86C983;
    border-radius: 3px;
    color: white;
}
.privateGardenContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.somethingWrong{
    color: red;
    font-size: 28px;
    font-weight: 500;
    margin-top: 40px;
}
.noResults{
    color: gray;
    font-size: 28px;
    font-weight: 500;
    margin-top: 40px;
}
.gardenSpecification{
    margin-left: 10px;
}
@media only screen and (max-width: 680px){
    .selectGarden{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}
.privateGardenComp{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mapInfo{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.mapCont{
    width: 60%;
    min-width: 600px;
    height: 500px;
}
.MapContainer{
    width: 100%;
    height: 100%;
}
.infoCont{
    width: 20%;
    min-width: 220px;
    max-width: 310px;
    height: 500px;
    margin-left: 10px;
}
.typeInfo{
    height: 100%;
    width: 100%;
    background-color: #FFC247;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items:center ;
    justify-content: flex-start;
}
.privateGFTitle{
    font-size: 22px;
    font-weight: 600;
    margin-top: 30px;
}
.privateGFImage{
    width: 33px;
}
.privateGFDecription{
    font-size: 14px;
    text-align: center;
    padding: 10px;
}
.privateGFVeg{
    font-size: 18px;
    font-weight: 600;
}
/*Data Component*/
.dataInfo{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.dataInfoPublic{
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.gardenInfoDataContainer{
    width: 24%;
    min-width: 300px;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 10px 20px 0px;
    padding: 15px;
    border-radius: 5px;
}
.colorWeather{
    background-color: #C9E7FF;
}
.colorCrop{
    background-color: #C8E19C;
}
.colorCompost{
    background-color: #DEEDC3;
}
.colorTank{
    background-color: #EFF8FF;
}
.gardenInfoDataContainer img{
    width: 70px;
}
.infodataTitle{
    font-size: 20px;
    font-weight: 600;
    margin: 5px auto;
}
.gardenInfoDataContainer hr{
    background-color: rgb(49, 49, 49);
    width: 100%;
}
.singleInfoContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
}
.infodataCont{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}
.infodataCol{
    width: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 120px;
}
.singleInfoContainer :first-child{
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
    text-align: center;
}
.singleInfoContainer :last-child{
    font-weight: 600;
    font-size: 18px;
    color: #001D42;
    text-align: center;
}

/*Notifications*/
.dataNotifications{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.notificationsTitle{
    font-weight: 600;
    font-size: 22px;
    margin: 10px;
}
.notificContainer{
    width: 100%;
    max-width: 1000px;
    background-color: #F5F5F5;
    padding: 10px;
    overflow-y: auto;
    max-height: 440px;
    min-height: 100px;
}
.singleNotification{
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    margin: 12px 0px;
    border-radius: 3px;
    box-shadow: -2px 2px 7px #c4c4c4 ;
}
.topNotific{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.timestampContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.marginR5{
    margin-right: 5px;
}
.midNotific{
    width: 100%;
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.bottNotific{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.bottNotific div{
    font-weight: 600;
    cursor: pointer;
}
.noNotes{
    padding: 10px;
    font-size: 22px;
}
.Gactions{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 40px 0px;
}
.rowWrapper{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
}
@media only screen and (max-width: 900px){
    .rowWrapper{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
    }
}
.actionBox{
    width: 24%;
    min-width: 300px;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 10px 20px 0px;
    padding: 10px;
    border-radius: 5px;
    background-color: #fcfcfc;
    box-shadow: 1px 3px 5px rgb(124, 124, 124);
}
.actionTitle{
    font-size: 20px;
    margin-top: 10px;
}
.overallCount{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.justifyC{
    justify-content: center;
}
.justifySB{
    justify-content: space-between;
}
.overallCount img{
    width: 28px;
    cursor: pointer;
}
.totalNum{
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10px;
}
.totalNum div{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    text-align: center;
    background-color: #dddddd;
    font-size: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.totalNum span{
    font-size: 18px;
    font-weight: 600;
}
.actionsPanel{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
}
.actionRow{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin: 10px auto;
}
.actNums{
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.arrowWidth{
    width: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.numWidth{
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.actionSymb{
    width: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.actionUpdate{
    width: 120px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #358F80;
    color: white;
    border-radius: 20px;
    cursor: pointer;
}
.selector{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}
.selectOption{
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
}
.selectOption span{
    text-align: center;
}
.optionC{
    width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #358F80;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}
.optionCSelected{
    width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #358F80;
    font-weight: 600;
    border: 1px solid #358F80;
    cursor: pointer;
    border-radius: 5px;
}
.modal-main {
    position:fixed;
    background: white;
    width: 40%;
    min-width: 300px;
    height: 220px;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    border-radius: 10px;
}
.senddataContainer{
      width: 100%;
      height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.senddataContainer h3{
      color:rgb(8, 8, 44);
      margin-top: -20px;
}
  
.display-block {
    display: block;
}
  
.display-none {
    display: none;
}
.closemodalV{
    width:100px;
    height: 30px;
    position: absolute;
    top: -10px;
    right: -50px;
    cursor: pointer;
}
.gobackButton{
    padding: 10px 20px;
    background-color: rgb(0, 44, 0);
    color: white;
    cursor: pointer;
    position: absolute;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    left: 50%;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}
.popButtons{
    width: 50%;
    min-width: 290px;
    margin: 20px auto 0 ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.popButtons div:first-child{
    width: 70px;
    padding: 5px;
    background-color: white;
    color: red;
    border: 1px solid red;
    text-align: center;
    border-radius: 3px ;
    font-size: 24px;
    cursor: pointer;
}
.popButtons div:last-child{
    width: 70px;
    padding: 5px;
    background-color: red;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 3px ;
    font-size: 24px;
    cursor: pointer;
}
.resMessage{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px auto;
    text-align: center;
}
.succ{
    font-size: 18px;
    color: rgb(25, 197, 25);
}
.err{
    font-size: 18px;
    color: rgb(238, 26, 26);
}
.leave{
    padding: 10px;
    width: 90px;
    background-color: rgb(8, 8, 44);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}

.columnPanel{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.CreategardenContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.addgardenfield{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 40px;
    margin-bottom: 40px;
}
.addgardenfieldtitle{
    font-size: 24px;
}
.addgardenfieldlabel{
    font-size:14px;
    color: rgb(104, 104, 104);
}
.addgardenfieldRest{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}
.inputsG{
    width: 100%;
    max-width: 300px;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}
.inputsN{
    width: 100%;
    max-width: 120px;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    margin-left: 10px;
}
.addimg{
    width: 30px;
    cursor: pointer;
    margin-left: 20px;
}
.deleteimg{
    width: 30px;
    cursor: pointer;
}
.addgardenfieldArray{
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.vegArrayData{
    margin-top: 5px;
    width: 100%;
    max-width: 490px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    background-color: ghostwhite;
    padding: 8px;
}
.vegArrayDataH{
    margin-top: 10px;
    width: 100%;
    max-width: 490px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(236, 236, 236);
    padding: 8px;
}
.centerCont{
    width: 33%;
    text-align: center
}

/*EDIT*/
.nameInput{
    width: 120px;
    padding:  4px;
    text-align: center;
}
.quantInput{
    width: 60px;
    padding:  4px;
    text-align: center;
}


/*options*/
.vegOptionCont{
    width: 155px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    margin-right: 5px;
}
.vegOptionCont img{
    height: 50px;
}
.vegOptionCont span{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}
.vegOptionSelected{
    background-color: #2F80ED;
    border-radius: 5px;
    width: 134px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    margin-top: 5px;
    cursor: pointer;
}
.vegOptionSelected img{
    width: 28px;
    margin-left: 8px;
}
.vegOptionSelected span{
    color: white;
    font-size: 16px;
    font-weight: 400;
}
.vegOption{
    border-radius: 5px;
    width: 134px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    margin-top: 5px;
    background-color: #F7F9FA;
    border: 1px solid #2F80ED;
    box-sizing: border-box;
    color: #2F80ED;
    cursor: pointer;
}
/*Map*/
.dragableMarker{
    width: 100%;
    max-width: 600px;
    height: 300px;
    margin-top: 10px;
}
.latlonGarden{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.latlonGarden div{
    margin-top: 15px;
}
/*buttons*/
.errorCont{
    width: 50%;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
}
.errbuttonsCont{
    width: 80%;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
    min-width: 350px;
}
.editbuttonsCont{
    width: 80%;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
    min-width: 350px;
}
.editbuttonsCont span{
    background-color: #27AE60;
    border-radius: 5px;
    font-size: 22px;
    padding: 12px 16px;
    color: white;
    margin: 10px;
    cursor: pointer;
}
.buttonsCont{
    width: 80%;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
    min-width: 350px;
}
.hiden{
    display: none;
    opacity: 0;
    visibility: hidden;
}
.buttonsCont :first-child{
    background-color: #27AE60;
    border-radius: 5px;
    font-size: 22px;
    padding: 12px 16px;
    color: white;
    margin: 10px;
    text-align: center;
    cursor: pointer;
}
.buttonsCont :last-child{
    background-color: #fc270b;
    border: 1px solid #fc270b;
    text-align: center;
    border-radius: 5px;
    font-size: 22px;
    padding: 12px 16px;
    color: white;
    margin: 10px;
    cursor: pointer;
}
.buttonsCont div:first-child:hover{
    background-color: #2b8d54;
    cursor: pointer;
}
.buttonsCont div:last-child:hover{
    background-color: white;
    border: 1px solid #fc270b;
    cursor: pointer;
    color: #fc270b;
}
@media only screen and (max-width: 680px){
    .buttonsCont :first-child{
        background-color: #27AE60;
        border-radius: 5px;
        font-size: 16px;
        padding: 8px 10px;
        color: white;
        margin: 10px;
        text-align: center;
        cursor: pointer;
    }
    .buttonsCont :last-child{
        background-color: #fc270b;
        border: 1px solid #fc270b;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        padding: 8px 10px;
        color: white;
        margin: 10px;
        cursor: pointer;
    }
}
.errorCreate{
    color: red;
    font-size: 22px
}
.sensorFields{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
}
.sensorFieldsVar{
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
}
.titleCodes{
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
}
.inputsSensor{
    width: 100%;
    max-width: 80px;
    padding: 3px 5px;
    display: inline-block;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    margin: 2px;
}
.variableS{
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid gainsboro;
    padding: 7px 10px;
    border-radius: 5px;
    margin-right: 3px;
}

.chartContainer{
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
}
