.registerPanel{
    width: 80%;
    min-width: 330px;
    max-width: 560px;
    height: 540px;
}
.registerFields{
    height: 650px;
    width: 100%;
    max-width: 400px;
    margin: -15px auto 0;
}
.regField{
    margin-top: 0px;
    padding: 0px;
}
.userlevelDrop{
    margin: 30px auto -20px;
    padding: 5px;
    width: 160px;
    font-size: 15px;
}