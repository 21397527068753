.buttonsSettings{
    width: 100%;
    padding:40px;
}

.editextcredSection{
    width: 100%;
    padding:10px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.actionbuttons{
    padding: 10px 18px;
    color: white;
    background-color: rgb(0, 184, 0);
    border-radius: 5px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 15px;
}
.actionbuttons:hover{
    background-color: rgb(0, 102, 0);
}
.buttonRow{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}
