.ForecastSubTitle{
    font-size: 22px;
}
.forecastInfo{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}
.stationDrop{
    padding: 7px;
    margin-top: 10px ;
    border-radius: 5px;
    width: 280px;
    font-size: 16px;
    background-color: #C4C4C4;
    cursor: pointer;
}
.stationIMG{
    margin: 10px 0 0 20px ;
    cursor: pointer;
}

.forecastTable{
    margin: 20px 40px;
    padding: 5px;
    background-color: #8CE1EB;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.forecastStation{
    background-color: #001D42;
    color: white;
    width: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.windForContainer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}
.forecastwindy{
    width: 55%;
    min-width: 320px;
    height: 510px;
    margin-left: 20px;
}
.agValues{
    width: 40%;
    min-width: 300px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: 20px;
    margin-bottom: 5px;
    background-color: #e2efff;
}
.columnWrap{
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
/**/
.foreValue{
    background-color: #FFFFFF;
    color: white;
    width: 250px;
    height: 90px;
    border-radius: 4px;
    border: 1px solid rgb(182, 182, 182);
    box-shadow: 2px 2px 3px rgb(194, 194, 194);
    color: black;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:5px 20px;
    margin: 5px;
}
.underTitle{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
}
.underTitle span{
    text-align: right;
}
.circle{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #97c2fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ImgTitle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.forecastImg{
    width: 28px;
}