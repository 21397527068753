.fieldBoxContainer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
}
.fieldBox{
    width: 160px;
    height: 160px;
    min-height: 160px;
    max-width: 160px;
    margin: 30px;
    border: 1px solid rgb(173, 173, 173);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 5px 10px 8px #888888;
    cursor: pointer;
    color: rgb(3, 24, 3);
    background-color: rgb(244, 252, 244);
}

/*mOBILE*/
@media only screen and (max-width: 500px) {
    .fieldBoxContainer{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
    }
}