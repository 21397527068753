.headerLand{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}
/*wave effect*/
.waveContainer{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(#469476, #004d49);
}


.iccsLogo{
    cursor: pointer;
    width: 150px;
}
.headerLeft{
    margin-left: 40px;
    margin-top: 80px;
}
.headerRight{
    margin-right: 100px;
    margin-top: 80px;
    width: 500px;
}
.LandingMenu{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
}
.LandingMenu span{
    cursor: pointer;
    padding: 3px 0;
    color: white;
}
.LandingMenu .register{
    padding: 12px 10px;
    background-color: #FFD7AB;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
.LandingMenuMobile{
    display: none;
}
.mobileMenuLand{
    display: none;
}
.mobileMenuLand span{
    width: 100%;
    text-align: center;
    color: white;
    padding: 8px 0;
    cursor: pointer;
}
.mobileMenuLand span:hover{
    background-color: white;
    opacity: 0.6;
    color: black;
}
.LandingMain{
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.bottomland{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-bottom: 100px;
}
.bottomland span{
    padding: 18px 48px;
    border-radius: 10px;
    background-color: white;
    color: black;
    cursor: pointer;
}
@media only screen and (max-width: 500px){
    .LandingMenu{
        display: none;
    }
    .LandingMenuMobile{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
    }
    .mobileMenuLand{
        width: 100%;
        height: 200px;
        z-index: 10;
        background-color: rgb(44, 83, 0);
        position:absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        top: 110px
    }
}

/*Main page*/
.MainPageLand{
    width: 100%;
    height: 100%;
    
}
.topwave{
    width: 100%;
    height: 80%;
    position: relative;
}
.bottomwave{
    width: 100%;
    height: 20%;
    background-color: #F2ECE4;
}
.MainPageLand:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 40%;
    width: 70%;
    height: 30%;
    background-color: #F2ECE4;
    right: 0px;
    top: 75vh;
}
.MainPageLand:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 30% 90%;
    width: 50%;
    height: 40vh;
    background-color: #F2ECE4;
    left: -40px;
    top: 65vh;
}

/*main content*/
.landImg{
    position: absolute;
    width: 40%;
    z-index: 5;
    top: 50vh;
    left: 30vw;
    transform: translate(-50%,-50%);
}
.landWelcome{
    position: absolute;
    width: 30%;
    z-index: 1;
    top: 40vh;
    left: 75vw;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
}
.landTitle{
    color: white;
    font-size: 34px;
    font-weight: 600;
    margin: 10px 0;
}
.landmain{
    color: white;
    font-size: 20px;
}

/*Landing Transition to mobile*/
@media only screen and (max-width: 680px){
    .MainPageLand{
        width: 100%;
        height: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 20px;
    }
    .topwave{
        position: relative;
        width: 100%;
        height: 100% ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .bottomwave{
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    .MainPageLand:before {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    .MainPageLand:after {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    /*main content*/
    .landImg{
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    .landWelcome{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 30%;
        left: 50%;
        width: 80%;
        z-index: 0;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .landTitle{
        color: white;
        font-size: 34px;
        font-weight: 600;
        margin: 10px 0;
    }
    .landmain{
        color: white;
        font-size: 20px;
    }
    .iccsLogo{
        cursor: pointer;
        width: 80px;
    }
}