.privateGardenComp{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mapInfo{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.mapCont{
    width: 60%;
    min-width: 600px;
    height: 500px;
}
.MapContainer{
    width: 100%;
    height: 100%;
}
.infoCont{
    width: 20%;
    min-width: 220px;
    max-width: 310px;
    height: 500px;
    margin-left: 10px;
}
.typeInfo{
    height: 100%;
    width: 100%;
    background-color: #FFC247;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items:center ;
    justify-content: flex-start;
}
.privateGFTitle{
    font-size: 22px;
    font-weight: 600;
    margin-top: 30px;
}
.privateGFImage{
    width: 33px;
}
.privateGFDecription{
    font-size: 14px;
    text-align: center;
    padding: 10px;
}
.privateGFVeg{
    font-size: 18px;
    font-weight: 600;
}
/*Data Component*/
.dataInfo{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.dataInfoPublic{
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.gardenInfoDataContainer{
    width: 24%;
    min-width: 300px;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 10px 20px 0px;
    padding: 15px;
    border-radius: 5px;
}
.colorWeather{
    background-color: #C9E7FF;
}
.colorCrop{
    background-color: #C8E19C;
}
.colorCompost{
    background-color: #DEEDC3;
}
.colorTank{
    background-color: #EFF8FF;
}
.gardenInfoDataContainer img{
    width: 70px;
}
.infodataTitle{
    font-size: 20px;
    font-weight: 600;
    margin: 5px auto;
}
.gardenInfoDataContainer hr{
    background-color: rgb(49, 49, 49);
    width: 100%;
}
.singleInfoContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
}
.infodataCont{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}
.infodataCol{
    width: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 120px;
}
.singleInfoContainer :first-child{
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
    text-align: center;
}
.singleInfoContainer :last-child{
    font-weight: 600;
    font-size: 18px;
    color: #001D42;
    text-align: center;
}

/*Notifications*/
.dataNotifications{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.notificationsTitle{
    font-weight: 600;
    font-size: 22px;
    margin: 10px;
}
.notificContainer{
    width: 100%;
    max-width: 1000px;
    background-color: #F5F5F5;
    padding: 10px;
    overflow-y: auto;
    max-height: 440px;
    min-height: 100px;
}
.singleNotification{
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    margin: 12px 0px;
    border-radius: 3px;
    box-shadow: -2px 2px 7px #c4c4c4 ;
}
.topNotific{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.timestampContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.marginR5{
    margin-right: 5px;
}
.midNotific{
    width: 100%;
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.bottNotific{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.bottNotific div{
    font-weight: 600;
    cursor: pointer;
}
.noNotes{
    padding: 10px;
    font-size: 22px;
}
.Gactions{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 40px 0px;
}
.rowWrapper{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
}
@media only screen and (max-width: 900px){
    .rowWrapper{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
    }
}
.actionBox{
    width: 24%;
    min-width: 300px;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 10px 20px 0px;
    padding: 10px;
    border-radius: 5px;
    background-color: #fcfcfc;
    box-shadow: 1px 3px 5px rgb(124, 124, 124);
}
.actionTitle{
    font-size: 20px;
    margin-top: 10px;
}
.overallCount{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.justifyC{
    justify-content: center;
}
.justifySB{
    justify-content: space-between;
}
.overallCount img{
    width: 28px;
    cursor: pointer;
}
.totalNum{
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10px;
}
.totalNum div{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    text-align: center;
    background-color: #dddddd;
    font-size: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.totalNum span{
    font-size: 18px;
    font-weight: 600;
}
.actionsPanel{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
}
.actionRow{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin: 10px auto;
}
.actNums{
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.arrowWidth{
    width: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.numWidth{
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.actionSymb{
    width: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.actionUpdate{
    width: 120px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #358F80;
    color: white;
    border-radius: 20px;
    cursor: pointer;
}
.selector{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}
.selectOption{
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
}
.selectOption span{
    text-align: center;
}
.optionC{
    width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #358F80;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}
.optionCSelected{
    width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #358F80;
    font-weight: 600;
    border: 1px solid #358F80;
    cursor: pointer;
    border-radius: 5px;
}