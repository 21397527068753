.loginPage{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainUserPanel{
    width: 100%;
    max-width: 500px;
    height: 600px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border-radius: 3px;
}
.LoginLogo{
    width: 100px;
}
.LoginLogoText{
    width: 200px;
    margin-left: 10px ;
}
.credentials{
    height: 650px;
    width: 100%;
    max-width: 400px;
    margin: 0px auto ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
}
.credFields{
    margin-top: 20px;
}
.responseField{
    margin-top: 60px;
}
.LoginButton{
    padding: 12px 35px;
    border-radius: 3px;
    background-color: green;
    color: white;
    margin-top: 10px;
    cursor: pointer;
}
.LoginButton:hover{
    background-color: rgb(0, 167, 0);
}
.Registerhere{
    margin-top: 40px;
}
.contacthelp{
    margin-top: 20px;
    text-align: center;
}
.Registerhere span , .contacthelp span{
    color: rgb(62, 62, 221);
    text-decoration: underline;
    cursor: pointer;
}