.sideBar{
    width: 180px;
    background-color: #001D42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}
.sideBarContainer .option{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 15px auto;
    text-align: center;
    color: white;
    padding: 20px;
    cursor: pointer;
}
.sideBarContainer .option:hover{
    background-color: #002d68;
    color: white;
}
.mobileMenu{
    display: none;
}
.sideBarClose{
    display: none;
}
.sideBarContainer{
    width: 100%;
}

.notificationSection{
    cursor: pointer;
}
.logOut{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    color: white;
    padding: 20px;
    cursor: pointer;
}
.logOut:hover{
    background-color: #002d68;
    color: white;
}
.sideImage{
    width: 20px;
    margin-right: 5px;
    margin-left: -5px;
}
/*Mobile*/
@media only screen and (max-width: 500px) {
    .sideBar{
        width: 100%;
        height: 400px;
        background-color: #001D42;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        top: 120px;
        justify-content: space-between;
    }
    .sideBarContainer .option{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 20px auto;
        background-color: inherit;
        text-align: center;
        color: white;
        padding: 5px;
        border-radius: 0px;
        cursor: pointer;
    }
    .sideBarContainer .option:hover{
        background-color: white;
        color: #001D42;
    }
    .sideImage{
        display: none;
    }
    .logOut{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        padding: 15px;
        cursor: pointer;
    }
}